import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Testing = () => {
  const [show, setShow] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const [login, setLogin] = useState({
    UserName: "",
    Password: "",
  });

  let count = 0;
  const NO_USER = "NO_USER";
  let LOGIN_FAILED = "LOGINFAILED";
  const LOCKED = "LOCKED";
  const BARRED = "BARRED";
  const NO_ROLE = "NO_ROLE";
  const handleChange = (e) => {
    setLogin({ ...login, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    console.log("CLICKEd");
    e.preventDefault();
    let json = {
      username: login.UserName,
      password: login.Password,
    };

    try {
      const response = await axios.post(
        "https://lateralpraxis.co.in/CommonAuthentication/Shared/Services/AuthService.asmx/ValidateUser",
        {
          json: JSON.stringify(json),
        }
      );

      let res = JSON.parse(response.data.d);
      const { ApplicationURL } = res;

      // If username is not entered
      if (response.data.d === JSON.stringify(NO_USER)) {
        return toast.error(
          "There is no user in the system with entered username!"
        );
      }
      if (response.data.d === JSON.stringify(LOCKED)) {
        return toast.error(
          "Your account has been locked out because you have exceeded the maximum number of incorrect login attempts. Please contact administrator to unblock your account!"
        );
      }
      if (response.data.d.slice(1, 12) === LOGIN_FAILED) {
        count++;
        if (count < 3) {
          return toast.error(`Password Incorrect!!!.Attempt ${count} out of 3`);
        } else {
          return toast.error(
            `Account has been locked due to more than ${count} attempts .Please contact administrator`
          );
        }
      } else if (response.data.d === JSON.stringify(BARRED)) {
        return toast.error(
          "Your account has been barred by the Administrator!"
        );
      } else {
        const redirect = async () => {
          try {
            window.location.replace(
              `${ApplicationURL}/?username=${login.UserName}`
            );
            // console.log(`${ApplicationURL}/?username=${login.UserName}`);
            // if (ApplicationURL) {
            //   const {
            //     config: { url, params },
            //   } = await axios.post(
            //     ApplicationURL+"/",
            //     { username: login.UserName },
            //     {
            //       params: { username: login.UserName },
            //     }
            //   );
            //   console.log(url)
            //   window.location=url;
            // }
          } catch (error) {
            console.log(error);
          }
        };

        redirect();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showPass = () => {
    console.log("PASS");
    setShow(!show);
  };
  return (
    <>
      <body>
        <section class="ftco-section">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-6 col-lg-5 custm-xl-5">
                <div class="login-wrap p-4 p-md-5">
                  <div class="fxt-header">
                    <a href="login-16.html" class="fxt-logo">
                      <img
                        src="img/InnoterraTech-Login page-ai.png"
                        alt="Logo"
                        class="img-fluid"
                      />
                    </a>
                    <h4>System Login</h4>
                  </div>
                  <form onSubmit={handleSubmit} class="login-form">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control rounded-left"
                        onChange={handleChange}
                        value={login.UserName}
                        placeholder="Username"
                        name="UserName"
                        required
                      />
                    </div>
                    <div class="form-group d-flex">
                      <span class="btn-show-pass">
                        <i class="zmdi zmdi-eye" onClick={showPass}></i>
                      </span>
                      <input
                        onChange={handleChange}
                        type={show ? "text" : "password"}
                        value={login.Password}
                        name="Password"
                        placeholder="Password"
                        type="password"
                        class="form-control rounded-left"
                        placeholder="Password"
                        required
                      />
                    </div>
                    <div class="form-group d-md-flex">
                      <div class="w-50">
                        <label class="checkbox-wrap checkbox-primary">
                          Keep me signed in
                          <input type="checkbox" checked />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="w-50 text-md-right">
                        <Link to="/forgotpassword">Forgot Password</Link>
                      </div>
                    </div>
                    <div class="form-group">
                      <button
                        type="submit"
                        onClick={() => {
                          setOpacity(0.5);
                          setTimeout(() => {
                            setOpacity(1);
                          }, 100);
                        }}
                        style={{ opacity: `${opacity}` }}
                        class="btn btn-primary btn-lg btn-block rounded submit p-3 px-5"
                      >
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="footer-area bg-img">
          <div class="footer-bottom-area fixed-bottom">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <div class="border-line"></div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="copywrite-text">
                    {/* <p> Innoterra Tech’ Dairy Management System</p> */}
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="footer-nav" style={{ float: "right" }}>
                    <div class="copywrite-text">
                      <p>
                        Development & Maintained by :
                        <a href="" target="_blank">
                          Innoterra Tech (India) Pvt. Ltd.
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </body>
      <ToastContainer position="top-center" />
    </>
  );
};

export default Testing;
