// import "./App.css";
import SignIn from "./components/SignIn";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ForgotPassword from "./components/ForgotPassword";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route path="/" exact>
            <SignIn />
            {/* <Testing /> */}
          </Route>
          <Route path="/forgotpassword" exact>
            <ForgotPassword />
            {/* <Forgot /> */}
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
