import image from "../images/captcha.jpg";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import CachedIcon from "@material-ui/icons/Cached";
import { TextField, Button } from "@material-ui/core";

const Forgot = () => {
  const history = useHistory();
  const [opacity, setOpacity] = useState(1);
  const [state, setState] = useState({
    username: "",
    emailId: "",
    captcha: "",
  });
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const [captchaBox, setcaptchaBox] = useState("");

  useEffect(() => {
    generateString();
  }, []);
  const generateString = () => {
    let randomString =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var length = "6";
    var result = "";
    for (let i = 0; i < length; i++) {
      result += randomString[Math.floor(Math.random() * randomString.length)];
    }
    setcaptchaBox(result);
  };
  const handleSubmit = async (e, props) => {
    e.preventDefault();
    let json = {
      username: state.username,
      emailId: state.emailId,
    };
    if (state.captcha === captchaBox) {
      try {
        const { data } = await axios.post(
          "https://lateralpraxis.co.in/CommonAuthentication/Shared/Services/AuthService.asmx/ForgotPassword",
          {
            json: JSON.stringify(json),
          }
        );
        if (data.d === "SUCCESS") {
          setState({
            username: "",
            emailId: "",
            captcha: "",
          });
          toast.success(
            "Your password has been reset succesfully.Password has been sent to registered email"
          );
          setTimeout(() => {
            history.push("/");
          }, 5000);
        } else {
          toast.error(data.d);
          generateString();
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("Invalid Captcha");
      generateString();
    }
  };
  return (
    <>
      <body>
        <section class="ftco-section">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-6 col-lg-5 custm-xl-5">
                <div class="login-wrap p-4 p-md-5">
                  <div class="fxt-header">
                    <a href="login-16.html" class="fxt-logo">
                      <img
                        src="img/InnoterraTech-Login page-ai.png"
                        alt="Logo"
                        class="img-fluid"
                      />
                    </a>
                    <h4>Reset your password</h4>
                    <form onSubmit={handleSubmit} class="login-form">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control rounded-left"
                          onChange={handleChange}
                          value={state.username}
                          placeholder="Username"
                          name="username"
                          required
                        />
                      </div>
                      <div class="form-group d-flex">
                        <input
                          onChange={handleChange}
                          value={state.emailId}
                          //    type={show ? "text" : "password"}
                          name="emailId"
                          placeholder="Email"
                          type="text"
                          class="form-control rounded-left"
                          required
                        />
                      </div>
                      <div style={{ marginLeft: "33%", marginBottom: 20 }}>
                        <input
                          value={captchaBox}
                          disabled
                          style={{
                            width: "150px",
                            height: "50px",
                            backgroundColor: "whitesmoke",
                            textAlign: "center",
                            fontFamily: "cursive",
                            fontSize: "25px",
                            color: "black",
                            fontWeight: "bolder",
                            backgroundImage: `url(${image})`,
                          }}
                        />
                        <button
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            marginLeft: 10,
                          }}
                          onClick={() => generateString()}
                        >
                          <CachedIcon />
                        </button>
                      </div>
                      <input
                        onChange={handleChange}
                        value={state.captcha}
                        //    type={show ? "text" : "password"}
                        name="captcha"
                        placeholder="Enter the code shown above"
                        type="text"
                        class="form-control rounded-left"
                        required
                      />

                      <div class="form-group d-md-flex">
                        <div class="w-50"></div>
                        <div
                          style={{ marginTop: 10 }}
                          class="w-50 text-md-right"
                        >
                          <a href="/">Back to login</a>
                        </div>
                      </div>
                      <div class="form-group">
                        <button
                          onClick={() => {
                            setOpacity(0.5);
                            setTimeout(() => {
                              setOpacity(1);
                            }, 100);
                          }}
                          style={{ opacity: `${opacity}` }}
                          type="submit"
                          class="btn btn-primary btn-lg btn-block rounded submit p-3 px-5"
                        >
                          Reset
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="footer-area bg-img">
          <div class="footer-bottom-area fixed-bottom">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <div class="border-line"></div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="copywrite-text">
                    <p> Innoterra Tech’ Dairy Management System</p>
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div class="footer-nav" style={{ float: "right" }}>
                    <div class="copywrite-text">
                      <p>
                        Development & Maintained by :
                        <a href="https://colorlib.com" target="_blank">
                          Innoterra Tech (India) Pvt. Ltd.
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </body>
      <ToastContainer position="top-center" />
    </>
  );
};

export default Forgot;
